<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper container pt-4 mb-3">
        Dane osobowe są przetwarzane:<br><br>
        <p>w celu świadczenia usług związanych z prowadzeniem i obsługą konta w Portalu/Aplikacji
        Mobilnej – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy
        (art. 6 ust. 1 lit. b) RODO), a w zakresie danych podanych fakultatywnie – podstawą prawną
        przetwarzania jest zgoda (art. 6 ust. 1 lit. a) RODO);
        </p>
        <p>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes
          Administratora (art. 6 ust. 1 lit. f) RODO), polegający na prowadzeniu analiz aktywności
          Użytkowników w Portalu/Aplikacji Mobilnej i sposobu korzystania z konta, a także ich preferencji,
          w celu poprawy stosowanych funkcjonalności; w związku z uzasadnionym interesem dane mogą
          być także udostępniane podmiotom związanym z Administratorem w ww. celach, w tym w celu
          budowania profilów, które mogą być wykorzystywane do poprawy jakości usług oraz
          dopasowywania treści oferowanych w Portal/Aplikację Mobilną przez te podmioty; w przypadku
          wyrażenia odpowiedniej zgody. </p>
      <p>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną
        przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO) polegający na
        ochronie swoich praw;</p>
      <p>w celach marketingowych Administratora oraz innych podmiotów – zasady przetwarzania danych
        osobowych w celach marketingowych zostały opisane w sekcji MARKETING.</p>
      <p>w celach marketingowych Administratora oraz innych podmiotów – zasady przetwarzania danych
        osobowych w celach marketingowych zostały opisane w sekcji MARKETING.</p>
    <p>Administrator umożliwia logowanie się do konta w Portalu/Aplikacji Mobilnej za pośrednictwem
      portali społecznościowych (Facebook). Jeżeli Użytkownik wybierze tę formę logowania,
      Portal/Aplikacja Mobilna, po uprzednim wyrażeniu zgody przez Użytkownika, pobiorą z konta
      Użytkownika w ramach portalu społecznościowego jedynie dane niezbędne do rejestracji i obsługi
      konta. Jeżeli Użytkownik umieszcza w Portalu/Aplikacji Mobilnej jakiekolwiek dane osobowe
      innych osób (w tym ich imię i nazwisko, adres, numer telefonu lub adres e-mail), może to uczynić
      jedynie pod warunkiem nienaruszenia przepisów obowiązującego prawa i dóbr osobistych tych
      osób.</p>
    <p><strong>DEFINICJE</strong></p>
    <p>Administrator: Bookme sp. z o. o. z siedzibą na Piotrkowskiej 122 m 7, 90-006 Łódź</p>
    <p>Dane osobowe: wszystkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie
    fizycznej poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną,
    genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej, w tym
    nr IP urządzenia, dane o lokalizacji, identyfikator internetowy oraz informacje gromadzone za
    pośrednictwem plików cookie oraz innej podobnej technologii.</p>
    <p>Polityka: niniejsza Polityka prywatności.</p>
    <p>RODO: Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
    roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
    sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.</p>
    <p>Portal: Portal dostępny pod adresem Bookme.com.pl</p>
    <p>Aplikacje Mobilne: Aplikacje mobilne, których operatorem jest Administrator, obsługiwane przez
    system operacyjny iOS, oraz Android.</p>
    <p>Użytkownik: każda osoba fizyczna odwiedzająca Portal/Aplikacje Mobilne lub korzystająca z
    jednej lub kilku usług lub funkcjonalności udostępnianych w Portalu/Aplikacji Mobilnej.
    </p>
    <p>Użytkownik: każda osoba fizyczna odwiedzająca Portal/Aplikacje Mobilne lub korzystająca z
    jednej lub kilku usług lub funkcjonalności udostępnianych w Portalu/Aplikacji Mobilnej.
    </p>
    <p><strong>PRZETWARZANIE DANYCH W ZWIĄZKU Z KORZYSTANIEM Z PORTALU/APLIKACJI
    MOBILNEJ</strong></p>
    <p>W związku z korzystaniem przez Użytkownika z Portalu oraz Aplikacji Mobilnej Administrator
    zbiera dane w zakresie niezbędnym do świadczenia poszczególnych usług oferowanych w
    Portalu/Aplikacji Mobilnej, a także informacje o aktywności Użytkownika w Portalu/Aplikacji
    Mobilnej. Poniżej zostały opisane szczegółowe zasady oraz cele przetwarzania danych osobowych
    gromadzonych podczas korzystania przez Użytkownika z Portalu/Aplikacji Mobilnej.
    </p>
  <p><strong>CELE ORAZ PODSTAWY PRAWNE PRZETWARZANIA DANYCH W Portalu/APLIKACJI
  Mobilnej</strong></p>
  <p>Korzystanie z Portalu/Aplikacji Mobilnej</p>
  <p>Dane osobowe wszystkich osób korzystających z Portalu/Aplikacji Mobilnej (w tym adres IP lub
  inne identyfikatory oraz informacje gromadzone za pośrednictwem plików cookies lub innych
  podobnych technologii), a nie będących zarejestrowanymi Użytkownikami (tj. osoby nie
  posiadające konta lub profilu w Portalu/Aplikacji Mobilnej) przetwarzane są przez Administratora:
  w celu świadczenia usług drogą elektroniczną w zakresie udostępniana Użytkownikom treści
  gromadzonych w Portalu/Aplikacji Mobilnej lub utrzymywania w Portalu/Aplikacji Mobilnej treści
  zamieszczonych przez Użytkowników (np. wpisy, komentarze) – wówczas podstawą prawną
  przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b) RODO),</p>
  <p>w celach analitycznych i statystycznych – wówczas podstawą prawną przetwarzania jest
  uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO), polegający na prowadzeniu analiz
  aktywności Użytkowników, a także ich preferencji, w celu poprawy stosowanych funkcjonalności i
  świadczonych usług;</p>
  <p>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną
  przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO) polegający na
  ochronie swoich praw;</p>
  <p>Aktywność Użytkownika w Portalu/Aplikacji Mobilnej, w tym jego dane osobowe, są rejestrowane
  w logach systemowych (specjalnym programie komputerowym służącym do przechowywania
  chronologicznego zapisu zawierającego informację o zdarzeniach i działaniach dotyczących
  systemu informatycznego służącego do świadczenia usług przez Administratora). Zebrane w logach
  informacje przetwarzane są przede wszystkim w celach związanych ze świadczeniem usług.
  Administrator przetwarza również te dane w celach technicznych, administracyjnych, na potrzeby
  zapewnienia bezpieczeństwa systemu informatycznego oraz zarządzania nim, a także w celach
  analitycznych i statystycznych – w tym zakresie podstawą prawną przetwarzania jest prawnie
  uzasadniony interes administratora (art. 6 ust. 1 lit. f) RODO).</p>
  <p>Rejestracja w Portalu/Aplikacji Mobilnej <br>
  Osoby, które dokonują rejestracji w Portalu/Aplikacji Mobilnej, proszone są o podanie danych
  niezbędnych do utworzenia i obsługi konta. Ponadto, w celu ułatwienia obsługi, Użytkownik może
  podać, w ustawieniach swojego konta, dodatkowe dane - takie dane można w każdym czasie
  usunąć. Podanie danych oznaczonych jako obowiązkowe jest wymagane w celu założenia i obsługi
  konta, a ich niepodanie skutkuje brakiem możliwości założenia konta. </p>
  <p><strong>FORMULARZE KONTAKTOWE</strong> <br>
    Administrator zapewnia możliwość skontaktowania się z nim przy wykorzystaniu elektronicznych
    formularzy kontaktowych. Skorzystanie z formularza wymaga podania danych osobowych,
    niezbędnych do skontaktowania się z Użytkownikiem i udzielenia odpowiedzi na zapytanie.
    Użytkownik może podać także inne dane, w celu ułatwienia kontaktu lub obsługi zapytania.
    Podanie danych oznaczonych jako obowiązkowe jest wymagane w celu przyjęcia i obsługi
    zapytania, a ich niepodanie skutkuje brakiem możliwości jego obsługi. Podanie pozostałych danych
    jest dobrowolne.</p>
    <p>Dane osobowe są przetwarzane:
    </p>
    <p>w celu identyfikacji nadawcy oraz obsługi jego zapytania przesłanego przez udostępniony
    formularz – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy
    o świadczenie usługi (art. 6 ust. 1 lit. b) RODO);</p>
    <p>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes
    Administratora (art. 6 ust. 1 lit. f) RODO), polegający na prowadzeniu statystyk zapytań
    zgłaszanych przez Użytkowników za pośrednictwem Portalu/Aplikacji Mobilnej w celu
    doskonalenia jego funkcjonalności.
    </p>
  <p><strong>MARKETING </strong><br>
  Administrator przetwarza dane osobowe Użytkowników w celu realizowania działań
  marketingowych, które mogą polegać na:</p>
  <p>wyświetlaniu Użytkownikowi treści marketingowych, które nie są dostosowane do jego preferencji
  (w tym reklama kontekstowa)</p>
  <p>kierowaniu e-mailowych powiadomień o interesujących ofertach lub treściach, które zawierają
  informacje handlowe (usługa newslettera)</p>
  <p>W celu realizowania działań marketingowych Administrator w niektórych przypadkach
  wykorzystuje profilowanie. Oznacza to, że dzięki automatycznemu przetwarzaniu danych
  Administrator dokonuje oceny wybranych czynników dotyczących osób fizycznych, w celu analizy
  ich zachowania lub stworzenia prognozy zachowań na przyszłość.
  </p>
  <p><strong>NEWSLETTER</strong> <br>
  Osobom, które w tym celu podały swój adres e-mail, Administrator świadczy usługę newslettera.
  Podanie danych jest wymagane w celu świadczenia usługi newslettera, a ich niepodanie skutkuje
  brakiem możliwości jego wysyłki.</p>
  <p>Dane osobowe są przetwarzane:</p>
  <p>w celu świadczenia usługi wysyłki newslettera – podstawą prawną przetwarzania jest niezbędność
  przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b) RODO)</p>
  <p>w przypadku kierowania do Użytkownika treści marketingowych w ramach newslettera – podstawą
  prawną przetwarzania, w tym profilowania jest uzasadniony interes Administratora (art. 6 ust. 1 lit.
  f) RODO), w związku z wyrażoną zgodą na otrzymywanie newslettera</p>
  <p>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes
  Administratora, (art. 6 ust. 1 lit. f) RODO) polegający na prowadzeniu analiz aktywności
  Użytkowników w Portalu/Aplikacji Mobilnej, w celu doskonalenia stosowanych funkcjonalności
  </p>
  <p>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną
  przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO).
  </p>
  <p><strong>PORTALE SPOŁECZNOŚCIOWE</strong> <br>
  Administrator przetwarza dane osobowe Użytkowników odwiedzających profile Administratora
  prowadzone w mediach społecznościowych (Facebook). Dane te są przetwarzane wyłącznie w
  związku z prowadzeniem profilu, w tym w celu informowania Użytkowników o aktywności
  Administratora oraz promowaniu różnego rodzaju wydarzeń, usług oraz produktów. Podstawą
  prawną przetwarzania danych osobowych przez Administratora w tym celu jest jego uzasadniony
  interes (art. 6 ust. 1 lit. f) RODO), polegający na promowaniu własnej marki.</p>
  <p><strong>PLIKI COOKIES ORAZ PODOBNA TECHNOLOGIA</strong> <br>
  Pliki cookies to małe pliki tekstowe, zapisywane na urządzeniu Użytkownika, przeglądającego
  Portal lub korzystającego z Aplikacji Mobilnej. Cookies zazwyczaj zawierają nazwę domeny
  Portalu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym
  oraz unikalny numer. W niniejszej Polityce informacje dotyczące cookies mają zastosowanie
  również do innych podobnych technologii wykorzystywanych w ramach Portalu/Aplikacji
  Mobilnej. <br>
  Administrator wykorzystuje również tzw. cookie Portalowe przede wszystkim w celu dostarczania
  Użytkownikowi usług świadczonych drogą elektroniczną oraz poprawy jakości tych usług. W
  związku z tym Administrator oraz inne podmioty świadczące na jego rzecz usługi analityczne oraz
  statystyczne korzystają z plików cookies, przechowując informacje lub uzyskując dostęp do
  informacji już przechowywanych w telekomunikacyjnym urządzeniu końcowym Użytkownika
  (komputer, telefon, tablet itp.). Pliki cookies wykorzystywane w tym celu obejmują:
  </p>
<ul>
  <li>- pliki cookies z danymi Użytkownika (identyfikator sesji) zapisywane na czas trwania sesji (ang.
user input cookies)</li>
<li>- uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania na czas
trwania sesji (ang. authentication cookies)
</li>
<li>- pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania
nadużyć w zakresie uwierzytelniania (ang. user centric security cookies)
</li>
<li>- sesyjne pliki cookies odtwarzaczy multimedialnych (np. pliki cookies odtwarzacza flash), na czas
trwania sesji (ang. multimedia player session cookies)
</li>
<li>- trwałe pliki cookies służące do personalizacji interfejsu Użytkownika, na czas trwania sesji lub
dłużej (ang. user interface customization cookies)</li>
<li>- pliki cookies służące do monitorowania ruchu na stronie internetowej, tj. analityki danych, w tym
cookies Google Analytics (są to pliki wykorzystywane przez spółkę Google – tj. podmiot, któremu
Administrator powierzył przetwarzanie danych osobowych - w celu prowadzenia analizy sposobu
korzystania z Portalu/Aplikacji Mobilnej przez Użytkownika, w tym tworzenia statystyk i raportów
dotyczących funkcjonowania Portalu/Aplikacji Mobilnej)
</li>
</ul>
  <p><strong>OKRES PRZETWARZANIA DANYCH OSOBOWYCH</strong> <br>
  Dane przetwarzane są przez czas świadczenia usługi, do czasu wycofania wyrażonej zgody lub
  zgłoszenia skutecznego sprzeciwu względem przetwarzania danych w przypadkach, gdy podstawą
  prawną przetwarzania danych jest uzasadniony interes Administratora.
  Okres przetwarzania danych może być przedłużony w przypadku, gdy przetwarzanie jest niezbędne
  do ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami, jednak nie dłużej, niż
  przez 10 lat, a po tym okresie, jedynie w przypadku i w zakresie, w jakim będą wymagać tego
  przepisy prawa. Po upływie okresu przetwarzania, dane są nieodwracalnie usuwane.</p>
  <p><strong>UPRAWNIENIA UŻYTKOWNIKA</strong> <br>
  Użytkownikowi przysługuje prawo: dostępu do treści danych oraz żądania ich sprostowania,
  usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia
  sprzeciwu względem przetwarzania danych, a także prawo wniesienia skargi do organu
  nadzorczego zajmującego się ochroną danych osobowych. W zakresie, w jakim podstawą
  przetwarzania danych Użytkownika jest zgoda, dane będą przetwarzane do czasu jej wycofania.
  Zgodę można wycofać w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z
  prawem przetwarzania dokonanego przed jej wycofaniem. Jeśli Użytkownik chce wycofać zgodę na
  przesyłanie newslettera lub przetwarzanie danych osobowych może zalogować się do swojego
  konta w Portalu/Aplikacji Mobilnej przy użyciu linku: bookme.com.pl/login. Użytkownik może też
  wysłać maila lub list na adres kontakt@bookme.com.pl lub zrezygnować poprzez kliknięcie w link
  w przesyłanej wiadomości newsletter.
  </p>
  <p><strong>PRAWO DO SPRZECIWU</strong> <br>
  Użytkownik ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania jego danych
  dla celów marketingu bezpośredniego, w tym profilowania, jeśli przetwarzanie odbywa się w
  związku z uzasadnionym interesem administratora. Użytkownik ma także prawo w dowolnym
  momencie wnieść sprzeciw wobec przetwarzania jego danych z przyczyn związanych z jego
  szczególną sytuacją w przypadkach, gdy postawą prawną przetwarzania danych jest uzasadniony
  interes Administratora (np. w związku z realizacją celów analitycznych i statystycznych, w tym
  profilowaniem).</p>
  <p><strong>ODBIORCY DANYCH</strong> <br>
  Dane osobowe pozyskiwane przez Administratora w związku z realizacją usług w Portalu/Aplikacji
  Mobilnej będą ujawniane zewnętrznym podmiotom, w tym w szczególności dostawcom
  odpowiedzialnym za obsługę systemów informatycznych służących do świadczenia usług w
  Portalu, agencjom marketingowym (w zakresie usług marketingowych) oraz podmiotom
  powiązanym z Administratorem. Podmioty powiązane z Administratora będą przetwarzały dane
  osobowe w celach analitycznych i statystycznych, w tym w celu budowania profilów
  Użytkowników, które wykorzystywane będą do poprawy jakości usług świadczonych przez
  podmioty z grupy Administratora oraz dopasowywania treści oferowanych w Portalu.
  Administrator zastrzega sobie prawo ujawnienia informacji dotyczących Użytkownika właściwym
  organom bądź osobom trzecim, które zgłoszą żądanie udzielenia takich informacji, w oparciu o
  odpowiednią podstawę prawną oraz zgodnie z przepisami obowiązującego prawa.
  </p>
  <p><strong>PRZEKAZYWANIE DANYCH POZA EUROPEJSKI OBSZAR GOSPODARCZY (W
  ZWIĄZKU Z LOGOWANIEM SIĘ ZA POMOCĄ FACEBOOKA ORAZ NANLITYKĄ
  GOOGLE)</strong> <br>
  Poziom ochrony danych osobowych poza Europejskim Obszarem Gospodarczym (EOG) różni się
  od tego zapewnianego przez prawo europejskie. Z tego powodu Administrator przekazuje dane
  osobowe poza EOG tylko gdy jest to konieczne, i z zapewnieniem odpowiedniego stopnia ochrony,
  przede wszystkim poprzez:</p>
  <ul>
    <li>- pliki cookies z danymi Użytkownika (identyfikator sesji) zapisywane na czas trwania sesji (ang.
  user input cookies)</li>
    <li>- współpracę z podmiotami przetwarzającymi dane osobowe w państwach, w odniesieniu do
    których została wydana stosowna decyzja Komisji Europejskiej</li>
    <li>- stosowanie standardowych klauzul umownych, wydanych przez Komisję Europejską</li>
    <li>- stosowanie wiążących reguł korporacyjnych, zatwierdzonych przez właściwy organ nadzorczy</li>
    <li>- w razie przekazywania danych do USA – współpracę z podmiotami uczestniczącymi w programie
  Tarcza Prywatności (Privacy Shield), zatwierdzonym decyzją Komisji Europejskiej</li>
  </ul>
  <p><strong>BEZPIECZEŃSTWO DANYCH OSOBOWYCH</strong> <br>
  Administrator na bieżąco prowadzi analizę ryzyka w celu zapewnienia, że dane osobowe
  przetwarzane są przez niego w sposób bezpieczny, zapewniający przede wszystkim, że dostęp do
  danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze
  względu na wykonywane przez nie zadania. Administrator dba o to, by wszystkie operacje na
  danych osobowych były rejestrowane i dokonywane jedynie przez uprawnionych pracowników i
  współpracowników.
  Administrator podejmuje wszelkie niezbędne działania, by także jego podwykonawcy i inne
  podmioty współpracujące dawały gwarancję stosowania odpowiednich środków bezpieczeństwa w
  każdym przypadku, gdy przetwarzają dane osobowe na zlecenie Administratora.</p>
  <p><strong>DANE KONTAKTOWE</strong> <br>
  Kontakt z Administratorem jest możliwy poprzez adres e-mail kontakt@bookme.com.pl lub
  pisemnie na adres Bookme sp. z o. o. ul. Piotrkowska 122 m. 34 90-006 Łódź.
  Administrator danych wyznaczył Inspektora Ochrony Danych, z którym można skontaktować się
  poprzez e-mail kontakt@bookme.com.pl, (w temacie wiadomości proszę napisać DANE
  OSOBOWE) w każdej sprawie dotyczącej przetwarzania danych osobowych.
  </p>
  <p><strong>ZMIANY POLITYKI PRYWATNOŚCI</strong> <br>
  Polityka jest na bieżąco weryfikowana i w razie potrzeby aktualizowana. Aktualna wersja Polityki
  została przyjęta i obowiązuje począwszy od 26. XI 2020 r.</p>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'PrivacyRules',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      title: 'Zasady ochrony prywatności'
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    margin-top: 30px;
    padding-bottom: 20px;
    font-weight: 200;
    font-size: 14px;
  }
  ul {
    padding: 1em;
    list-style-type: none;
  }
</style>
